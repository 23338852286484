<template>
  <aside
    class="iv2-bottom-nav theme__bottom-bg position-fixed text-center d-md-none"
  >
    <nav class="iv2-bottom-nav__sub-menu">
      <ul class="iv2-bottom-nav__sub-list list-unstyled">
        <li
          v-for="nav in bottom"
          :key="nav.id"
          class="iv2-bottom-nav__sub-item"
        >
          <router-link
            v-if="nav.link_type == 'internal'"
            :to="nav.link_href + lang + query"
            :target="nav.target_type"
            class="iv2-bottom-nav__sub-link theme__bottom-link"
          >
            <i
              class="iv2-bottom-nav__sub-item-icon d-block"
              :class="nav.item_icon"
            ></i>
            <span class="iv2-bottom-nav__sub-item-text d-block">{{
              nav.item_name
            }}</span>
          </router-link>
          <a
            v-else-if="nav.link_type == 'external'"
            :href="nav.link_href"
            :target="nav.target_type"
            @click="closeMenu"
            rel="noopener"
            class="iv2-bottom-nav__sub-link theme__bottom-link"
          >
            <i
              class="iv2-bottom-nav__sub-item-icon d-block"
              :class="nav.item_icon"
            ></i>
            <span class="iv2-bottom-nav__sub-item-text d-block">{{
              nav.item_name
            }}</span>
          </a>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<script>
export default {
  props: {
    bottom: Array,
  },

  data() {
    return {
      query: "",
      lang: "",
    };
  },

  mounted() {
    if (this.$route.query.mode == "preview") {
      this.query = "?mode=preview";
    }
    if (this.$route.query.hl) {
      this.lang = "?hl=" + this.$route.query.hl;
    }
  },

  methods: {
    closeMenu() {
      var nav = document.getElementsByClassName("iv2-nav");
      var body = document.body;
      nav[0].classList.remove("active");
      body.classList.remove("noscroll");
    },
  },
};
</script>

<style lang="scss">
/* SP Only */
@media (max-width: 767px) {
  .iv2-bottom-nav {
    display: block;
    border-left: none;
    mix-blend-mode: normal;
    padding: 0;

    .iv2-bottom-nav__sub-menu {
      .iv2-bottom-nav__sub-list {
        margin-bottom: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        align-items: center;

        .iv2-bottom-nav__sub-item {
          margin-bottom: 0;
          display: inline-block;
          width: 80px;
          padding: 10px 0;

          .iv2-bottom-nav__sub-link {
            -webkit-transition: 0.3s;
            transition: 0.3s;
            vertical-align: middle;
            padding: 0;

            &:hover {
              text-decoration: none;
              opacity: 0.5;
            }
          }

          .iv2-bottom-nav__sub-item-icon {
            margin-bottom: 3px;
            font-size: 1.2rem;
          }

          .iv2-bottom-nav__sub-item-text {
            font-size: 10px;
            font-weight: 400;
            line-height: 1.2;
            padding: 0 8px;
          }
        }
      }
    }
  }
}
</style>

<template>
  <section
    v-if="items.is_open_pickup === 1 && pickup.length > 0"
    class="iv2-section-pickup"
  >
    <div class="theme__sub-bg row m-0">
      <div class="col-md-4 iv2-top-pickup__head">
        <div
          class="iv2-top-pickup__head--block theme__pickup--text d-none d-md-inline-block"
        >
          <p class="iv2-top-pickup__head--subtitle">
            {{ $store.state.themeLang.general.pickup_subtitle }}
          </p>
          <h3 class="iv2-top-pickup__head--title">
            {{ $store.state.themeLang.general.pickup_title }}
          </h3>
        </div>
      </div>
      <div class="col-md-8 iv2-top-pickup__summary">
        <div v-for="(item, index) in limitCount" :key="item.id">
          <transition :name="trans_name">
            <div v-if="current == index" class="iv2-top-pickup__item">
              <p class="pt-4 pt-md-5 m-0">
                <span class="iv2-top-pickup__item--date">{{
                  item.opened_at
                }}</span>
                <span class="iv2-top-pickup__item--label">{{
                  item.category_name
                }}</span>
              </p>
              <router-link
                :to="{
                  name: item.type + 'Detail',
                  params: { id: item.id },
                  query: Object.assign({}, $route.query),
                }"
                class="iv2-top-pickup__item--link"
              >
                <p class="iv2-top-pickup__item--title m-0">
                  {{ item.title | trimText }}
                </p>
              </router-link>
            </div>
          </transition>
        </div>
        <div v-if="pickup.length > 1" class="iv2-top-pickup__slide">
          <span @click="prev()" class="iv2-top-pickup__slide--arrow">
            <i class="fas fa-long-arrow-alt-left"></i>
          </span>
          <span>{{ current + 1 }}/{{ limitCount.length }}</span>
          <span @click="next()" class="iv2-top-pickup__slide--arrow">
            <i class="fas fa-long-arrow-alt-right"></i>
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    items: Object,
  },

  data() {
    return {
      pickup: [],
      params: {
        limit: "",
      },
      current: 0,
      trans_name: "next",
      screenWidth: "",
    };
  },

  computed: {
    limitCount() {
      return this.pickup.slice(0, this.params.limit);
    },
  },

  filters: {
    trimText(text) {
      return window.innerWidth <= 767 && text.length > 18
        ? text.slice(0, 18) + "..."
        : text;
    },
  },

  created() {
    this.params = {
      limit: this.items.display_pickup_number,
      is_pickup: 1,
    };
  },

  mounted() {
    const pickupRepository = this.$repository.get("news");
    const pickup = pickupRepository.index(this.params);
    const self = this;
    pickup
      .then((result) => {
        self.pickup = result.data.response.data;
      })
      .catch((error) => {
        console.error(error);
      });

    setInterval(() => {
      this.next();
    }, 6000);

    window.innerWidth > 767
      ? (this.screenWidth = true)
      : (this.screenWidth = false);
  },

  updated() {
    this.gridHeight();
  },

  methods: {
    gridHeight() {
      let items = document.getElementsByClassName("iv2-top-pickup__item");
      let block = document.getElementsByClassName("iv2-top-pickup__summary");
      let titleMaxHeight = 0;
      let titleArray = new Array();
      let slide =
        this.pickup.length > 1
          ? document.getElementsByClassName("iv2-top-pickup__slide")[0]
              .clientHeight
          : 32;

      Array.prototype.forEach.call(items, (el) => {
        el.style.height = "";
        titleArray.push(el.clientHeight);
      });

      titleMaxHeight = Math.max.apply(null, titleArray);

      Array.prototype.forEach.call(block, (el) => {
        el.style.height =
          window.innerWidth > 767 ? titleMaxHeight + slide + "px" : "150px";
      });
    },

    prev() {
      this.trans_name = "prev";
      setTimeout(() => {
        this.current--;
        if (this.current == -1) {
          this.current = this.limitCount.length - 1;
        }
      }, 50);
    },

    next() {
      this.trans_name = "next";
      setTimeout(() => {
        this.current++;
        if (this.current == this.limitCount.length) {
          this.current = 0;
        }
      }, 50);
    },
  },
};
</script>

<style lang="scss">
.iv2-section-pickup {
  padding: 0 50px 40px;

  > .row {
    border-radius: 15px;
  }

  .iv2-top-pickup__head {
    padding: 0;
    text-align: right;

    .iv2-top-pickup__head--block {
      text-align: left;
      padding: 20px 80px 20px 0;
      position: relative;

      .iv2-top-pickup__head--title {
        font-size: 36px;
        font-weight: bold;
      }

      .iv2-top-pickup__head--subtitle {
        font-size: 14px;
      }

      &::after {
        content: "";
        height: 120px;
        border-right: 1px solid #707070;
        position: absolute;
        top: 40px;
        right: 0;
      }
    }
  }

  .iv2-top-pickup__summary {
    padding-top: 20px;
    padding-left: 80px;
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;

    .iv2-top-pickup__item {
      position: absolute;
      top: 0;

      .iv2-top-pickup__item--date {
        font-size: 14px;
        margin-right: 20px;
      }

      .iv2-top-pickup__item--label {
        text-align: center;
        border: 1px solid;
        font-size: 14px;
        padding: 5px 30px;
      }

      .iv2-top-pickup__item--link {
        &:hover {
          text-decoration: none;
        }

        .iv2-top-pickup__item--title {
          padding: 1rem 1rem 1rem 0px;
          font-weight: bold;
        }
      }
    }
  }

  .iv2-top-pickup__slide {
    text-align: right;
    font-weight: bold;
    position: absolute;
    bottom: 0;
    width: 80%;

    .iv2-top-pickup__slide--arrow {
      margin: 0 15px;
      cursor: pointer;
    }
  }
}

/* TABLET Only */
@media (min-width: 768px) and (max-width: 1199px) {
  .iv2-section-pickup {
    padding: 0 50px 70px;

    .iv2-top-pickup__head {
      .iv2-top-pickup__head--block {
        padding: 20px 24px 20px 0;
      }
    }

    .iv2-top-pickup__summary {
      padding-left: 24px;
    }
  }
}

/* SP Only */
@media (max-width: 767px) {
  .iv2-section-pickup {
    padding: 15px 15px 30px;

    .iv2-top-pickup__head {
      text-align: left;

      .iv2-top-pickup__head--block {
        padding: 10px 25px;

        &::after {
          content: none;
        }
      }
    }

    .iv2-top-pickup__summary {
      margin-bottom: 15px;
      padding: 0 20px;
    }

    .iv2-top-pickup__slide {
      width: 90%;
    }
  }
}
</style>

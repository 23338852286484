<template>
  <div class="iv2-news-item__tile--triple">
    <router-link
      :to="{
        name: 'newsDetail',
        params: { id: content.id },
        query: Object.assign({}, $route.query),
      }"
      class="iv2-news-item__link"
    >
      <div class="iv2-news-item__thumbnail fit-img">
        <picture>
          <source
            type="image/webp"
            :srcset="
              content.thumbnail + $store.state.publicLang.general.image_webp
            "
          />
          <img
            :src="content.thumbnail"
            class="iv2-news-item__img m-0"
            :alt="
              '「' + content.title + '」' + $store.state.publicLang.news.alt
            "
          />
        </picture>
      </div>

      <div class="iv2-news-item__data mt-4">
        <div class="row">
          <div class="col-6 pr-0">
            <small v-if="content.opened_at" class="iv2-news-item__date">{{
              content.opened_at
            }}</small>
            <small
              v-if="!content.days_excess"
              class="iv2-news-item__new ml-1"
              >{{ $store.state.themeLang.general.new_label }}</small
            >
          </div>
          <div class="col-6" style="padding-top: 3px">
            <small class="iv2-news-item__label theme__main-label d-block">{{
              content.category_name
            }}</small>
          </div>
        </div>
      </div>
      <h3 class="iv2-news-item__title">{{ content.title }}</h3>
      <p v-if="content.content" class="iv2-news-item__summary m-0 pt-2">
        {{ content.content }}
      </p>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    content: Object,
  },
};
</script>

<style lang="scss">
.iv2-news-item__tile--triple {
  .iv2-news-item__link {
    display: block;
    text-decoration: none;

    &:hover .iv2-news-item__thumbnail {
      opacity: 0.7;
    }

    .iv2-news-item__thumbnail {
      height: 200px;
      -webkit-transition: 0.3s;
      transition: 0.3s;

      .iv2-news-item__img {
        border-radius: 10px;
      }
    }

    .iv2-news-item__title {
      font-size: 1.1rem;
      font-weight: bold;
    }

    .iv2-news-item__summary {
      font-size: 1rem;
      line-height: 1.5;
    }

    .iv2-news-item__new {
      color: #a83535;
    }

    .iv2-news-item__label {
      width: 100%;
      text-align: center;
      border-radius: 8px;
    }
  }
}
</style>

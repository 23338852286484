<template>
  <div class="iv2-news-item__tail--double">
    <router-link
      :to="{
        name: 'newsDetail',
        params: { id: content.id },
        query: Object.assign({}, $route.query),
      }"
      class="iv2-news-item__link"
    >
      <div class="iv2-news-item__block theme__main-bg row">
        <div class="iv2-news-item__thumbnail fit-img col-sm-3 col-5">
          <picture>
            <source
              type="image/webp"
              :srcset="
                content.thumbnail + $store.state.publicLang.general.image_webp
              "
            />
            <img
              :src="content.thumbnail"
              class="iv2-news-item__img m-0"
              :alt="
                '「' + content.title + '」' + $store.state.publicLang.news.alt
              "
            />
          </picture>
        </div>

        <div class="col-sm-9 col-7">
          <div class="d-sm-flex text-left">
            <p v-if="content.opened_at" class="iv2-news-item__date size-14 m-0">
              {{ content.opened_at }}
            </p>
            <p class="iv2-news-item__label size-14 d-block ml-sm-2">
              {{ content.category_name }}
            </p>
            <p
              v-if="!content.days_excess"
              class="iv2-news-item__new size-14 m-0 ml-sm-2"
            >
              {{ $store.state.themeLang.general.new_label }}
            </p>
          </div>
          <div class="d-none d-sm-block">
            <h3 class="iv2-news-item__title">{{ content.title }}</h3>
            <p v-if="content.content" class="iv2-news-item__summary m-0 pt-2">
              {{ content.content }}
            </p>
          </div>
        </div>
        <div class="d-sm-none col-12 p-0">
          <h3 class="iv2-news-item__title mt-2">{{ content.title }}</h3>
          <p v-if="content.content" class="iv2-news-item__summary m-0 pt-2">
            {{ content.content }}
          </p>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    content: Object,
  },
};
</script>

<style lang="scss">
.iv2-news-item__tail--double {
  height: 100%;

  .iv2-news-item__link {
    display: block;
    text-decoration: none;
    height: 100%;

    &:hover .iv2-news-item__thumbnail {
      opacity: 0.7;
    }

    .iv2-news-item__block {
      padding: 20px;
      margin: 0 10px;
      border-radius: 10px;
      height: 100%;
    }

    .iv2-news-item__thumbnail {
      height: 123px;
      max-width: 123px;
      -webkit-transition: 0.3s;
      transition: 0.3s;

      .iv2-news-item__img {
        border-radius: 10px;
      }
    }

    .iv2-news-item__title {
      font-size: 1.1rem;
      font-weight: bold;
      margin: 0;
    }

    .iv2-news-item__summary {
      font-size: 1rem;
      line-height: 1.5;
    }

    .iv2-news-item__data,
    .iv2-news-item__new,
    .iv2-news-item__label {
      font-size: 1rem;
    }

    .iv2-news-item__label {
      margin: 0;
    }
  }
}
</style>
